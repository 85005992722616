<template>
  <div class="paypal-completed">

    <h1>Payment Complete</h1>
    <p>Thank you for paying the £2 Joining Fee to activate your account.</p>
    <p>We have emailed you a receipt. Log in to your PayPal account to view transaction details.</p>
    <p>You will now be able to <a href="https://cashbox.cuaccount.com/login/"
       target="_blank" class="text-uppercase btn btn-success">log into our member’s area</a> where you will be able to check your account balances and apply for a loan.</p>

  </div>
</template>

<script>
export default {
  name: 'PayPalCompleted',
  metaInfo() {
    return {
      title: 'Payment Complete | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
